<style lang="less" scoped>
.dialog-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  .dialog-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .close-area {
      flex: 1;
    }
    .dialog {
      width: 6.44rem;
      background: white;
      border-radius: 0.16rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .dialog-head {
        width: 100%;
        height: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.36rem;
        color: #272727;
        font-weight: bold;
      }
      .dialog-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 5.8rem;
        justify-content: center;
        color: #939393;
        input {
          width: 5.32rem;
          border: none;
          height: 0.92rem;
          padding: 0 0.24rem;
          font-size: 0.28rem;
          margin: 0;
          outline: none;
          background: #efefef;
          border-radius: 0.06rem;
          &::placeholder {
            font-size: 0.28rem;
            color: #aeaeae;
          }
          &:focus {
            border: none;
          }
          &:active {
            border: none;
          }
        }
        .tips {
          margin-bottom: 0.34rem;
          width: 5.8rem;
          font-size: 0.28rem;
          color: #333333;
          .email {
            color: #69d9b6;
          }
        }
        .codes {
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 1.48rem;
          .ecode {
            font-size: 0.28rem;
            color: #54cea4;
            line-height: 0.34rem;
          }
        }
        .warning {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 0.08rem;
          margin-bottom: 0.24rem;
          display: flex;
          font-size: 0.24rem;
          color: #acacac;
          svg {
            width: 0.28rem;
            height: 0.28rem;
            margin-right: 0.04rem;
          }
        }
        .upgrade-tips {
          font-weight: 400;
          font-size: 0.28rem;
          color: #606060;
          background: #f5f5f5;
          border-radius: 16px;
          padding: 0.12rem 0.12rem;
          margin-left: -0.12rem;
          margin-right: -0.12rem;
        }

        .upgrade-detail {
          margin-top: 0.1rem;
          margin-bottom: 0.16rem;
          font-weight: 400;
          font-size: 0.28rem;
          color: #606060;
          border-radius: 16px;
          padding: 0.12rem 0.12rem;
          margin-left: -0.12rem;
          margin-right: -0.12rem;
          line-height: 0.4rem;
        }
      }
      .dialog-footer {
        width: 100%;
        border-top: 0.02rem solid #eeeeee;
        height: 1.1rem;
        display: flex;
        .dialog-cancel {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.36rem;
          width: 50%;
          border-right: 0.02rem solid #eeeeee;
          color: #888888;
          cursor: pointer;
        }
        .dialog-confirm {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          font-size: 0.36rem;
          color: #54cea4;
          cursor: pointer;
        }
      }
    }
    .close-area {
      flex: 1;
    }
  }
}
</style>
<template>
  <div class="dialog-mask" v-if="display">
    <div class="dialog-container">
      <div class="close-area" @click="closeDialog"></div>
      <div class="dialog" :style="{ 'max-height': dialogHeightMap[type] }">
        <div class="dialog-head" v-if="type == 1">重发邀请</div>
        <div class="dialog-head" v-else-if="type == 2">换邮箱</div>
        <div class="dialog-head" v-else-if="type == 3">点卡代码</div>
        <div class="dialog-head" v-else-if="type == 4">升级高级会员</div>
        <div class="dialog-head" v-else-if="type == 5">加速器配置</div>
        <div class="dialog-head" v-else-if="type == 6">重发邀请</div>
        <div class="dialog-body" v-if="type == 1">
          <div class="tips" style="margin-top: 0.24rem; margin-bottom: 0.12rem">
            1、原提交邮箱：<span class="email">{{ email }}</span>
          </div>
          <div class="tips">
            2、重发前请确认您提交的邮箱是否填写错误(注意大小写)，如错误请在发送邀请 24 小时之后来进行修改
          </div>
        </div>
        <div class="dialog-body" v-else-if="type == 2">
          <input v-model="changedEmail" :placeholder="email" />
          <div class="tips" style="margin-top: 0.24rem; margin-bottom: 0.12rem">
            1、只能换一次账号进入家庭，请谨慎更换
          </div>
          <div class="tips" style="margin-bottom: 0.24rem">
            2、如果邮箱填写错误(注意大小写)需要发送邀请 24 小时之后方能修改，请仔细填写
          </div>
        </div>
        <div class="dialog-body" v-else-if="type == 3">
          <div class="tips" style="margin-bottom: 0.1rem">代码：</div>
          <div class="codes">
            <div class="ecode" v-for="(code, idx) in codes">{{ code }}</div>
          </div>
          <div class="warning">
            <svg
              t="1622802890114"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3316"
              width="200"
              height="200"
            >
              <path
                d="M511.87 33.99c-265.1 0-480 214.9-480 480s214.9 480 480 480 480-214.9 480-480-214.91-480-480-480z m-0.27 757.33c-27.61 0-50-22.39-50-50s22.39-50 50-50 50 22.39 50 50c0 27.62-22.39 50-50 50z m37.67-179.49c0 19.18-16.48 34.73-36.8 34.73-20.33 0-36.8-15.55-36.8-34.73 0 0-22.66-249.86-22.69-333.45-0.01-24.73 26.64-41.73 59.5-41.73 30.66 0 58.29 15.52 58.29 41.01-0.01 84.93-21.5 334.17-21.5 334.17z"
                fill="#7F7F7F"
                p-id="3317"
              ></path>
            </svg>
            <div>请将点卡尽快充值到您的账号上</div>
          </div>
        </div>
        <div class="dialog-body" v-else-if="type == 4">
          <div class="upgrade-tips">
            1、升级规则：升级后的高级会员剩余时间和普
            通会员的剩余时间一致，升级高级会员所需金
            额=普通会员剩余时间折算金额+差价
            <br />
            2、确定升级后，将不能撤回
            <br />
            3、确定升级后，你将会收到新的家庭邀请，请 登录邮箱接受邀请
          </div>
          <div class="upgrade-detail">
            提交邮箱：{{ upgrade.email }}<br />
            升级后高级会员到期时间：{{ upgrade.expiredAt }}<br />
            普通会员剩余时间折算金额：<span style="color: #54cea4">{{
              upgrade.remainPrice ? upgrade.remainPrice + '元' : ''
            }}</span
            ><br />
            还需要支付差价：<span style="color: #54cea4">{{
              upgrade.deltaPrice ? upgrade.deltaPrice + '元' : ''
            }}</span>
          </div>
        </div>
        <div class="dialog-body" v-else-if="type == 5">
          <div class="upgrade-detail">
            服务器：{{ accelerator.server }}<br />
            用户名：{{ accelerator.username }}<br />
            密码：{{ accelerator.password }}<br />
            端口：{{ accelerator.port }}<br />
            查看流量地址：<span
              style="color: #54cea4; cursor: pointer"
              @click="viewAcceleratorInfo"
              >{{
                'https://carpool.yyouren.com/accelerator/info?username=' +
                accelerator.username
              }}</span
            >
          </div>
        </div>
        <div class="dialog-body" v-else-if="type == 6">
          <input v-model="changedEmail" :placeholder="email" />
          <div class="tips" style="margin-top: 0.24rem; margin-bottom: 0.12rem">
            1、如需发送到新邮箱，请直接填写新邮箱后点击确定
          </div>
          <div class="tips" style="margin-bottom: 0.24rem">
            2、如果邮箱填写错误(注意大小写)需要发送邀请 24 小时之后方能修改，请仔细填写
          </div>
        </div>
        <div class="dialog-footer" v-if="type == 1 || type == 2 || type == 6">
          <div class="dialog-cancel" @click="cancel">取消</div>
          <div class="dialog-confirm" @click="confirm">确定</div>
        </div>
        <div class="dialog-footer" v-else-if="type == 3 || type == 5">
          <div class="dialog-confirm" style="width: 100%" @click="cancel">
            确定
          </div>
        </div>
        <div class="dialog-footer" v-else-if="type == 4">
          <div class="dialog-cancel" @click="cancel">取消</div>
          <div class="dialog-confirm" @click="confirm">去支付差价</div>
        </div>
      </div>
      <div class="close-area"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  data () {
    return {
      changedEmail: '',
      dialogHeightMap: {
        1: '4.48rem',
        2: '4.76rem',
        3: '4.62rem',
        4: '7.10rem',
        5: '5.62rem'
      }
    }
  },
  props: {
    title: {
      type: String,
      default: '重发邀请'
    },
    email: {
      type: String,
      default: ''
    },
    display: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 1
    },
    upgrade: {
      type: Object,
      default () {
        return {}
      }
    },
    accelerator: {
      type: Object,
      default () {
        return {}
      }
    },
    codes: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('cancel')
    },
    cancel () {
      this.$emit('cancel')
    },
    confirm () {
      this.$emit('confirm', this.changedEmail)
    },
    viewAcceleratorInfo () {
      this.$router.push(
        '/accelerator/info?username=' + this.accelerator.username
      )
    }
  }
}
</script>
