<template>
  <div class="letter-mask" v-if="visible">
    <div class="containner">
      <div class="blank"></div>
      <div class="letter-wrap">
        <div class="letter-header">
          <div class="label">告用户的一封信</div>
          <div @click="close" class="close-icon-wrap">
            <div class="close-icon"></div>
          </div>
        </div>
        <slot></slot>
        <div class="letter-footer">
          <div class="letter-cancel" @click="cancel">{{ cancelText }}</div>
          <div class="letter-confirm" @click="confirm">{{ confirmText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    confirmText: {
      type: String,
      default: '退款并领取优惠'
    },
    visible: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: '暂不'
    }
  },
  data () {
    return {}
  },
  methods: {
    close () {
      this.$emit('cancel')
    },
    cancel () {
      this.$emit('cancel')
    },
    confirm () {
      this.$emit('confirm')
    }
  }
}
</script>
<style scoped lang="less">
.letter-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.65);
  .containner {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .blank {
      flex: 1;
      width: 7.5rem;
    }
    .letter-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 7.5rem;
      max-height: 10.4rem;
      min-height: 10.4rem;
      background: #ffffff;
      padding-bottom: 0.24rem;
      border-radius: 0.2rem 0.2rem 0px 0px;
      .letter-header {
        display: flex;
        position: relative;
        width: 100%;
        align-items: center;
        height: 1rem;
        .label {
          margin-left: 0.24rem;
          font-size: 0.28rem;
          font-weight: bold;
          color: #333333;
        }
        .close-icon-wrap {
          position: absolute;
          cursor: pointer;
          top: 0.2rem;
          right: 0.24rem;
          width: 0.8rem;
          height: 0.8rem;
          .close-icon {
            width: 0.5rem;
            height: 0.5rem;
            &::before,
            &::after {
              content: '';
              position: absolute; /*方便进行定位*/
              height: 0.3rem;
              width: 0.02rem;
              border-radius: 0.04rem;
              top: 0.1rem;
              right: 0.21rem; /*设置top和right使图像在20*20框中居中*/
              background: rgba(0, 0, 0, 0.5);
            }
            &::before {
              transform: rotate(45deg); /*进行旋转*/
            }
            &::after {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .letter-footer {
        height: 1.1rem;
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        .letter-cancel {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.32rem;
          color: #666666;
          cursor: pointer;
          width: 3.32rem;
          height: 0.8rem;
          background: #ebebeb;
          border-radius: 0.4rem;
        }
        .letter-confirm {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.32rem;
          height: 0.8rem;
          background: #4a4a4a;
          border-radius: 0.4rem;
          font-size: 0.36rem;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
