<style lang="less" scoped>
.prompt-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  .prompt-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .close-area {
      flex: 1;
    }
    .prompt {
      width: 6.44rem;
      background: white;
      border-radius: 0.16rem;
      display: flex;
      flex-direction: column;
    }
    .close-area {
      flex: 1;
    }
  }
}
</style>
<template>
  <div class="prompt-mask" v-if="visible">
    <div class="prompt-container">
      <div class="close-area" @click="closeDialog"></div>
      <div class="prompt">
        <slot name=header></slot>
        <slot name=body></slot>
        <slot name=footer></slot>
      </div>
      <div class="close-area"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prompt',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    closeDialog () {
      this.$emit('cancel')
    },
    cancel() {
      this.$emit('cancel')
    },
  }
}
</script>
