<template>
  <div class="order-wrapper">
    <div class="search-input" v-if="!isWeixin">
      <div class="input-suffix">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px"
          viewBox="0 0 18 18" version="1.1">
          <path fill="#a6aaae"
            d="M14.7799392,12.7157712 L12.7518578,10.6932409 C12.9673523,10.3951473 13.1498275,10.0727903 13.2940698,9.73136915 C13.5460594,9.13344887 13.6729231,8.49913345 13.6729231,7.8457539 C13.6729231,7.19237435 13.5443215,6.55805893 13.2940698,5.96013865 C13.0507695,5.3830156 12.7014599,4.86481802 12.2583059,4.41941075 C11.8151518,3.97573657 11.2990077,3.62564991 10.7203007,3.37954939 C10.1207393,3.12824957 9.48815865,3 8.83646153,3 C8.18302656,3 7.54870803,3.12824957 6.9526224,3.3830156 C6.37739108,3.62738302 5.8595091,3.97573657 5.41461721,4.42287695 C4.96972531,4.87001733 4.62041565,5.3882149 4.37885326,5.96360485 C4.12686371,6.56152513 4,7.19584055 4,7.8492201 C4,8.50259965 4.12860156,9.13691508 4.37885326,9.73483536 C4.62215351,10.3119584 4.97146317,10.830156 5.41461721,11.2755633 C5.85777125,11.7192374 6.37391536,12.0693241 6.9526224,12.3154246 C7.54870803,12.5684575 8.1812887,12.6967071 8.83646153,12.6967071 C9.48815865,12.6967071 10.1224772,12.5684575 10.7203007,12.3154246 C11.0661346,12.1681109 11.3945899,11.982669 11.6952395,11.762565 L13.7198453,13.7816291 C13.8658254,13.9272097 14.0587278,14 14.2498922,14 C14.4427946,14 14.6339591,13.9272097 14.7816771,13.7816291 L14.7834149,13.779896 C15.0736374,13.4818024 15.0718995,13.0051993 14.7799392,12.7157712 L14.7799392,12.7157712 Z M12.3816938,7.847487 C12.3816938,9.80935875 10.7915529,11.4038128 8.83646153,11.4038128 C6.88137018,11.4038128 5.29122922,9.80762565 5.29122922,7.847487 C5.29122922,5.88734835 6.88137018,4.29116118 8.83646153,4.29116118 C10.7915529,4.29116118 12.3816938,5.88734835 12.3816938,7.847487 Z"
            id="形状" />
        </svg>
      </div>
      <input placeholder="请输入联系方式" v-model="contact" />
    </div>
    <div class="search-button" v-if="!isWeixin">
      <button :disabled="!contact" :class="{ disabled: !contact }" @click="searchOrder">
        查订单
      </button>
    </div>
    <OrderSync @sync1="orderSync(1)" :isWeixin="isWeixin" @sync2="orderSync(2)"></OrderSync>
    <div class="order-list" v-if="orders.length">
      <div class="order" :key="idx" v-for="(order, idx) in orders">
        <div class="order-first-line">
          <div>下单时间：{{ formatToYMDHMS(order.orderAt) }}</div>
          <div class="status" v-if="[1, 6, 7].includes(order.type)" :style="{ color: order.color }">
            {{
              order.status == 2
                ? '待支付'
                : order.status === 3
                  ? '待上车'
                  : order.status == 4
                    ? '已上车'
                    : order.status == 5
                      ? '已完成'
                      : 6 === order.status
                        ? '邀请邮件待接受'
                        : order.status == 7
                          ? '已驳回'
                          : order.status == 8
                            ? '已过期'
                            : order.status == 9
                              ? '已退款'
                              : '已完成'
            }}
          </div>
          <div class="status" v-else :style="{ color: order.color }">
            {{ order.status == 2 ? '待支付' : '已完成' }}
          </div>
        </div>
        <div class="order-second-line" v-if="[1, 6, 7].includes(order.type) && order.expiredAt">
          <div>到期时间：{{ formatToYMDHMS(order.expiredAt) }}</div>
          <div class="car-type" v-if="[1, 6, 7].includes(order.type)">
            {{ order.stName == '高级包车' ? '包车' : order.stName }}
          </div>
        </div>
        <div class="order-second-line">
          <div>
            商品：{{
              [1, 6, 7].includes(order.type)
                ? nameShowMap[order.stName]
                : order.stName == '下载流量'
                  ? 'eshop下载流量套餐'
                  : order.stName == '流量包'
                    ? 'eshop下载加量包'
                    : order.meta.productName
            }}
          </div>
        </div>
        <div class="order-second-line" v-if="[1, 6, 7].includes(order.type)">
          <div>邮箱：{{ order.email }}</div>
        </div>
        <div class="order-third-line" :style="{ marginBottom: order.status !== 2 ? '0.12rem' : '0px' }">
          <div>
            {{
              order.type == 5
                ? `${order.meta.skuName} 数量 *${order.meta.buyNumber}`
                : ([1, 6, 7].includes(order.type) ? ' 时长：' : '流量：') +
                order.name
            }}
          </div>

          <div class="amount">实付：￥{{ order.price }}</div>
        </div>
        <div class="repay-line">
          <div class="repay-btn" v-if="order.status == 2" @click="repay(order)">
            去支付
          </div>
          <!-- <div
            class="repay-btn"
            v-if="order.status == 4 && [1, 6, 7].includes(order.type)"
            @click="renewal(order)"
          >
            续费
          </div> -->
          <div class="repay-btn" v-if="order.status == 4 && [1, 6, 7].includes(order.type)" @click="checkStatus(order)">
            查会员状态
          </div>
          <div class="repay-btn" v-if="
            (order.status == 6 ||
              (order.status == 8 &&
                new Date(order.expiredAt) > new Date())) &&
            [1, 6, 7].includes(order.type)
          " @click="reSendEmail(order)">
            重发邮件
          </div>
          <div class="repay-btn" :class="{ limited: order.meta.limitedType }"
            v-if="order.status == 6 && [1, 6, 7].includes(order.type)" @click="limitedOrderHandle(order)">
            限制加入专用
          </div>
          <div class="repay-btn" v-if="order.status == 3 && order.type == 5" @click="viewCodes(order)">
            查看点卡
          </div>
          <div class="repay-btn" v-if="
            order.status == 3 &&
            (order.stName == '下载流量' || order.stName == '流量包')
          " @click="viewAccelerator(order)">
            查看配置
          </div>
        </div>
      </div>
    </div>
    <div class="result-empty" v-if="!isWeixin && orderFlag && !orders.length">
      暂无订单
    </div>
    <div class="wx-result-empy" v-if="isWeixin && !orders.length">暂无订单</div>
    <div v-if="orders.length && isWeixin" class="order-desc"
      :style="{ 'margin-top': orders.length ? '0.36rem' : '1.6rem' }">
      <div style="margin-top: -0.1rem; font-weight: 500; margin-bottom: 0.1rem">
        订单状态说明
      </div>
      <div class="order-status">
        <div v-if="orders.filter(order => order.status == 3).length">
          <span style="font-weight: bold">待上车：</span>等待系统给您发送邀请邮件
        </div>
        <div v-if="orders.filter(order => order.status == 6).length">
          <span style="font-weight: bold">邀请邮件待接受：</span>已发送邀请邮件给您，等待您接受进入家庭
        </div>
        <div v-if="orders.filter(order => order.status == 4).length">
          <span style="font-weight: bold">已上车：</span>已经进家庭组
        </div>
        <div v-if="orders.filter(order => order.status == 5).length">
          <span style="font-weight: bold">已完成：</span>服务时长到期或者是换邮箱、升级会员等操作，退出家庭组
        </div>
        <div v-if="orders.filter(order => order.status == 7).length">
          <span style="font-weight: bold">已驳回：</span>芝麻信用截图截图提交审核不通过，等待您联系客服重新提交截图，超过 24
          小时未重新提交系统将自动发送快捷车邀请给您
        </div>
        <div v-if="orders.filter(order => order.status == 8).length">
          <span style="font-weight: bold">已过期：</span>邀请邮件超过24小时未接受，邮件作废，请重新发送邮件
        </div>
      </div>
    </div>
    <div v-if="!isWeixin" class="order-desc" :style="{ 'margin-top': orders.length ? '0.36rem' : '1.6rem' }">
      <span style="font-weight: bold">说明：</span>
      <span>用订单提交时您所预留的联系方式查询（手机号）</span>
      <br />
      <div style="font-weight: bold; margin-top: 0.18rem">注意：</div>
      <span>1、为保证订单信息安全，仅能查询近10天内的订单，如有疑问请联系客服</span>
      <br />
      <span>2、查无订单时可能是联系方式错误</span>
      <br />
      <span>3、订单状态说明</span><br />
      <div class="order-status">
        <div v-if="orders.filter(order => order.status == 3).length">
          <span style="font-weight: bold">待上车：</span>等待系统给您发送邀请邮件
        </div>
        <div v-if="orders.filter(order => order.status == 6).length">
          <span style="font-weight: bold">邀请邮件待接受：</span>已发送邀请邮件给您，等待您接受进入家庭
        </div>
        <div v-if="orders.filter(order => order.status == 4).length">
          <span style="font-weight: bold">已上车：</span>已经进家庭组
        </div>
        <div v-if="orders.filter(order => order.status == 5).length">
          <span style="font-weight: bold">已完成：</span>服务时长到期或者是换邮箱、升级会员等操作，退出家庭组
        </div>
        <div v-if="orders.filter(order => order.status == 7).length">
          <span style="font-weight: bold">已驳回：</span>芝麻信用截图截图提交审核不通过，等待您联系客服重新提交截图，超过 24
          小时未重新提交系统将自动发送快捷车邀请给您
        </div>
        <div v-if="orders.filter(order => order.status == 8).length">
          <span style="font-weight: bold">已过期：</span>邀请邮件超过24小时未接受，邮件作废，请重新发送邮件
        </div>
      </div>
    </div>
    <Loading :display="showLoading"></Loading>
    <Toast :showToast="showToast" :message="toastMsg" @disappear="showToast = false"></Toast>
    <Dialog :display="showDialog" @cancel="dialogCancel" @confirm="dialogConfirm" :email="order.email"
      :type="dialogType" :upgrade="upgrade" :accelerator="acceleratorInfo" :codes="order.codes"></Dialog>
    <Letter :visible="refundDialogShow" :confirmText="'退款并领取优惠'" :cancelText="'暂不'" @confirm="refundConfirm"
      @cancel="refundCancel">
      <div class="letter-body">
        <div class="paragraph" style="margin-top: 0">
          尊敬的用户：<br />因任天堂家庭规则更新(限制用户频繁出入家庭组)，为响应新规则，平台将对部分连续续费4年的家长号<span style="color: #fc0000">停止续费</span>）。
        </div>
        <div class="paragraph">
          届时<span
            style="color: #fc0000">极小部分用户</span>将会提前失去会员状态，同时为了保障您的权益，在您失去会员的同时，我们将对您的剩余时间进行退款。请点击下方「退款并领取优惠」，款项将在<span
            style="color: #fc0000">1小时内原路返回，其中，如果支付的订单超过1年，我们将以发红包的方式进行发送，请及时查收！</span>

        </div>
        <div class="paragraph">
          2023年10月30号之前加入我们家庭的账号，操作退款后，重新下单上车即可。
        </div>
        <div class="paragraph">
          虽此事是由任天堂规则变更引起，但为表达我们的歉意，<span style="color: #fc0000">我们将为您提供专属优惠券，您在平台重新下单时即可使用，期待再次为您贴心服务！</span>
        </div>
        <div class="refund-info">
          会员剩余时间：{{ refundInfo.daysRemaining }} 天 <span v-if="!isNaN(new Date(refundInfo.expiredAt))">({{ new
            Date(refundInfo.expiredAt).toISOString().substring(0, 10) }}停止)</span>
          <div style="color: #fc0000">
            您将收到退款金额：{{ refundInfo.refundAmount }} 元<br />
            确定退款后将获得 1 张优惠券，欢迎重新下单
          </div>
        </div>
      </div>
    </Letter>

    <DialogCoupon :visible="couponDialogShow">
      <template #header> 恭喜获得 </template>

      <template #body>
        <div style="font-size: 0.28rem; color: #333333">
          退款并领取优惠券成功，重新下单时即可使用
        </div>

        <div class="coupons-list" v-if="coupons.length">
          <div class="coupon-box" @click="couponTap(index)" v-for="(coupon, index) in coupons">
            <div class="coupon-head" :class="{ unavailable: tabIdx == 1 }">
              <div class="coupon-amount-info">
                <div class="coupon-amount">{{ coupon.discount }}</div>
                <div class="coupon-currency">折</div>
              </div>
              <div class="coupon-condition">无门槛</div>
            </div>
            <div class="coupon-body" :class="{ unavailable: tabIdx == 1 }">
              <div class="coupon-info">
                <div class="coupon-name">{{ coupon.name }}</div>
                <div class="coupon-expiration">
                  {{
                    getYMD(coupon.createdAt) + ' - ' + getYMD(coupon.expiredAt)
                  }}
                </div>
              </div>
              <div class="coupon-check" v-if="tabIdx == 0">
                <svg v-if="index == couponActIdx" t="1679918602414" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="2759" width="0.40rem" height="0.40rem">
                  <path
                    d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m-74.965333 550.4L346.453333 545.152a42.666667 42.666667 0 1 0-60.330666 60.330667l120.704 120.704a42.666667 42.666667 0 0 0 60.330666 0l301.653334-301.696a42.666667 42.666667 0 1 0-60.288-60.330667l-271.530667 271.488z"
                    fill="#52C41A" p-id="2760"></path>
                </svg>
                <div v-else class="check"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="coupons-list" v-else style="justify-content: center">
          <span style="font-size: 0.28rem; color: #999999">暂无优惠券</span>
        </div>
      </template>

      <template #footer>
        <div class="coupon-dialog-footer" @click="couponDialogShow = false">
          开心收下
        </div>
      </template>
    </DialogCoupon>

    <ResendDialog :visible="limitedHandlerParams.resendDialogShow">
      <template #header>
        <div class="resend-dialog-header">
          <div class="resend-dialog-header-title">限制加入专用</div>
          <div @click="resendDialogCancel" class="close-icon-wrap">
            <div class="close-icon"></div>
          </div>
        </div>
      </template>
      <template #body>
        <div class="resend-dialog-body" v-if="limitedHandlerParams.resendDialogStep == 1">
          您在接受邀请邮件的时候，出现以下「由於限制加入家庭，無法參加。」的情况？
          <img class="resend-pic" src="../../assets/resend-01.png" />
          <img class="resend-pic" src="../../assets/resend-02.png" />
        </div>
        <div class="resend-dialog-body" v-if="limitedHandlerParams.resendDialogStep == 2">
          请仔细确认此账号加入家庭情况，如不确定加入的时间可以登录邮箱查看历史加入的时间。
          <img class="resend-pic" src="../../assets/resend-03.png" />

          <div class="invitation-detail">
            <div class="invitation-tip">
              您的账号在2023年11月01日之后，有新接受并且成功加入过其他家庭的邀请吗？
            </div>

            <div class="invitation-situation-box" @click="resendDialogTap(idx)"
              v-for="(invitation, idx) in ['加入过', '没加入过']">
              <div class="situation-label">{{ invitation }}</div>
              <div class="situation-check">
                <svg v-if="idx == limitedHandlerParams.resendDialogTapIdx" t="1679918602414" class="icon"
                  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2759" width="0.40rem"
                  height="0.40rem">
                  <path
                    d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m-74.965333 550.4L346.453333 545.152a42.666667 42.666667 0 1 0-60.330666 60.330667l120.704 120.704a42.666667 42.666667 0 0 0 60.330666 0l301.653334-301.696a42.666667 42.666667 0 1 0-60.288-60.330667l-271.530667 271.488z"
                    fill="#52C41A" p-id="2760"></path>
                </svg>
                <div v-else class="check"></div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="resend-dialog-footer">
          <div class="letter-cancel" @click="resendDialogCancel">取消</div>
          <div class="letter-confirm" @click="resendDialogConfirm">
            {{
              limitedHandlerParams.resendDialogStep == 1
                ? '是的，下一步'
                : '重发新邀请'
            }}
          </div>
        </div>
      </template>
    </ResendDialog>
    <Prompt :visible="limitationPrompt.show">
      <template #header>
        <div class="prompt-header">限制加入家庭</div>
      </template>
      <template #body>
        <div class="prompt-body">
          <div class="email">邮箱：{{ limitationPrompt.email }}</div>
          {{ limitationPrompt.content }}
        </div>
      </template>
      <template #footer>
        <div class="prompt-footer" @click="limitationPromptCancel">知道了</div>
      </template>
    </Prompt>

    <DialogCoupon :visible="syncDialogShow">
      <template #header> 同步订单到小程序 </template>

      <template #body v-if="syncType == 1">
        <div style="
            color: #969799;
            /* 14/CN-Regular */
            font-family: PingFang SC;
            font-size: 0.28rem;
            font-style: normal;
            width: 90%;
            font-weight: 400;
            line-height: 0.4rem; /* 142.857% */
          ">
          确定后，您在这边的订单将同步到 youthswitch
          小程序，后续查看订单和相关操作都可以直接去小程序～
        </div>
      </template>
      <template #body v-else>
        <input v-model="phoneNumber" placeholder="请输入手机号" />
        <div style="
            color: #969799;
            /* 14/CN-Regular */
            font-family: PingFang SC;
            font-size: 0.28rem;
            font-style: normal;
            margin-top: 0.24rem;
            width: 90%;
            font-weight: 400;
            line-height: 0.4rem; /* 142.857% */
          ">
          <span style="color: #f00">1.每个微信只能同步1次手机号，请谨慎填写 </span><br />
          2.确定后，此手机号关联的订单将同步到 youthswitch 小程序
        </div>
      </template>
      <template #footer>
        <div class="sync-dialog-footer">
          <div class="op-btn cancel" @click="syncDialogShow = false">取消</div>
          <div class="op-btn confirm" @click="syncDialogConfirm">确定同步</div>
        </div>
      </template>
    </DialogCoupon>
  </div>
</template>
<script>
// @ is an alias to /src
import { getOrders } from '@/api/order'
import { getAcceleratorInfo } from '@/api/accelerator/index'
import { newOrder, refund } from '@/api/carpool/order'
import { updatePassengerStatus, checkStatus } from '@/api/carpool/index'
import { utils } from '@/utils'
import Loading from '@/components/Loading'
import Dialog from '@/components/Dialog'
import Toast from '@/components/Toast'
import Letter from '@/components/Letter'
import Prompt from '@/components/Prompt'
import DialogCoupon from '@/components/DialogCoupon'
import ResendDialog from '@/components/ResendDialog'
import { reportError } from '@/utils/log'
import { getCoupons, updateUser } from '@/api/user'
import OrderSync from '@/components/OrderSync.vue'

export default {
  name: 'Home',
  data() {
    let uuid = localStorage.getItem('uuid')
    return {
      limitationPrompt: {
        show: false,
        content: '',
        email: ''
      },
      phoneNumber: '',
      nameShowMap: {
        快捷车: 'NS普通家庭会员',
        芝麻车: 'NS普通家庭会员',
        包车: 'NS普通家庭会员（包车）',
        高级快捷车: 'NS高级家庭会员',
        高级芝麻车: 'NS高级家庭会员',
        高级包车: 'NS高级家庭会员（包车）',
        升级快捷车: '升级快捷车',
        升级芝麻车: '升级芝麻车'
      },
      syncDialogShow: false,
      syncType: 2,
      dialogType: 1,
      showToast: false,
      showDialog: false,
      toastMsg: '',
      dialogTitle: '重发邀请',
      contact: '',
      orders: [],
      orderFlag: false,
      uuid,
      isWeixin: false,
      showLoading: false,
      order: {
        codes: [],
        changedEmail: '',
        orderId: '',
        email: '',
        op: 'reSendEmail',
        status: 6
      },
      upgrade: {
        deltaPrice: '',
        remainPrice: '',
        expiredAt: '',
        email: '',
        orderId: ''
      },
      acceleratorInfo: {},
      refundDialogShow: false,
      refundInfo: {},
      couponDialogShow: false,
      coupons: [],
      limitedHandlerParams: {
        resendDialogTapIdx: -1,
        resendDialogStep: 1,
        resendDialogShow: false
      }
    }
  },
  components: {
    Loading,
    Dialog,
    Toast,
    Letter,
    DialogCoupon,
    ResendDialog,
    Prompt,
    OrderSync
  },
  methods: {
    syncDialogConfirm() {
      if (this.phoneNumber) {
        this.$router.push({
          query: {
            phoneNumber: this.phoneNumber
          }
        })
      }

      let appid = 'wx1d62934604321a9c'
      let redirectUrl = window.location.href
      redirectUrl = encodeURI(redirectUrl)

      // https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx807d86fb6b3d4fd2&redirect_uri=http%3A%2F%2Fdevelopers.weixin.qq.com&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect

      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
    },
    orderSync(syncType) {
      if (!this.isWeixin) {
        let input = document.createElement('input')
        input.value = 'https://carpool.yyouren.com/order'
        document.body.appendChild(input)
        input.select()
        input.setSelectionRange(0, input.value.length)
        document.execCommand('Copy')
        document.body.removeChild(input)
        this.toastMsg = '链接已复制，请到微信直接打开此链接进行同步哦'
        this.showToast = true
        return
      }

      this.syncType = syncType
      this.syncDialogShow = true
    },
    limitationPromptCancel() {
      this.limitationPrompt.content = ''
      this.limitationPrompt.email = ''
      this.limitationPrompt.show = false
    },
    limitedOrderHandle(order) {
      this.order = order

      if (order.status == 6) {
        if (order.meta.limitedType == 1) {
          // 家庭问题
          this.limitationPrompt.email = order.email
          this.limitationPrompt.content =
            '2分钟内您将收到新邀请，请登录邮箱接受最新邀请，如果还是限制加入的话，请联系客服处理。'
          this.limitationPrompt.show = true
        } else if (order.meta.limitedType == 2) {
          this.limitationPrompt.email = order.email
          this.limitationPrompt.content =
            '由于任天堂新规不能频繁加入家庭，您近期加入过其他家庭，很遗憾无法再加入新家庭。请联系客服处理，欢迎过段时间再来下单！'
          this.limitationPrompt.show = true
        } else {
          this.limitedHandlerParams.resendDialogShow = true
        }
      }
    },
    resendDialogTap(idx) {
      this.limitedHandlerParams.resendDialogTapIdx = idx
    },
    resendDialogCancel() {
      this.limitedHandlerParams.resendDialogStep = 1
      this.limitedHandlerParams.resendDialogShow = false
      this.limitedHandlerParams.resendDialogTapIdx = -1
    },
    async resendDialogConfirm() {
      if (this.limitedHandlerParams.resendDialogStep == 1) {
        this.limitedHandlerParams.resendDialogStep += 1
      } else {
        if (this.limitedHandlerParams.resendDialogTapIdx !== -1) {
          this.showLoading = true
          let res = await updatePassengerStatus({
            uuid: this.uuid,
            op: 'limitation-resend',
            beenJoined:
              this.limitedHandlerParams.resendDialogTapIdx === 0 ? true : false,
            orderId: this.order.orderId
          })
          this.showLoading = false
          if (res.data.code == 0) {
            this.limitedHandlerParams.resendDialogShow = false
            this.toastMsg =
              this.limitedHandlerParams.resendDialogTapIdx === 0
                ? '任天堂新规不能频繁加入家庭，请联系客服进行处理'
                : '2分钟内您将收到新邀请，请登录邮箱接受'
            this.order.status =
              this.limitedHandlerParams.resendDialogTapIdx === 0 ? 6 : 3
            this.showToast = true
          } else {
            this.toastMsg = res.data.message
            this.showToast = true
          }
        } else {
          this.toastMsg = '请先勾选是否加入过其他家庭'
          this.showToast = true
        }
      }
    },
    async getCoupons() {
      try {
        let res = await getCoupons({
          uuid: localStorage.getItem('uuid')
        })

        if (res.data.code == 0) {
          if (res.data.coupons.length && res.data.coupons[0].coupons) {
            this.coupons = res.data.coupons[0].coupons
            this.couponDialogShow = true
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async refundConfirm() {
      this.showLoading = true
      try {
        let res = await refund({
          refundId: this.refundInfo.refundId
        })

        this.showLoading = false

        if (res.data.code == 0) {
          this.refundDialogShow = false

          this.getCoupons()

          this.searchOrder()
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      } catch (error) {
        console.error(error)
      }
    },
    refundCancel() {
      this.refundDialogShow = false
    },
    async checkStatus(order) {
      try {
        this.showLoading = true
        let res = await checkStatus({
          orderId: order.orderId
        })

        this.showLoading = false

        if (res.data.code == 0) {
          this.toastMsg = '状态正常，账号是会员'
          this.showToast = true
        } else if (res.data.code == 1) {
          this.refundDialogShow = true
          this.refundInfo = res.data.refund
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    renewal(order) {
      if (order.email) {
        this.$router.push('/carpool/renewal?email=' + order.email)
      } else {
        this.$router.push('/carpool/renewal')
      }
    },
    async viewAccelerator(order) {
      try {
        this.showLoading = true
        let res = await getAcceleratorInfo({
          orderId: order.orderId
        })
        this.showLoading = false
        if (res.data.code == 0) {
          this.dialogType = 5
          this.showDialog = true
          this.acceleratorInfo = res.data.info
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      } catch (error) {
        reportError(error)
      }
    },
    getYMD(date) {
      return utils.getYMD(date)
    },
    formatToYMDHMS(date) {
      return utils.formatToYMDHMS(date)
    },
    dialogCancel() {
      this.showDialog = false
    },
    checkMail(email) {
      return /^[0-9a-zA-Z_.+-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(
        email
      )
    },
    async dialogConfirm(email) {
      if (this.dialogType == 4) {
        let orderParams = {
          uuid: localStorage.getItem('uuid'),
          cf: localStorage.getItem('cf'),
          upgrade: this.upgrade
        }
        this.showLoading = true
        let res = await newOrder(orderParams)
        this.showLoading = false
        if (res.data.code == 0) {
          this.$router.push(
            `/carpool/upgrade?orderid=${res.data.order.orderId}`
          )
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
          return
        }
      } else {
        if (this.order.op == 'changeEmail') {
          if (!this.checkMail(email)) {
            this.toastMsg = '请输入正确的邮箱'
            this.showToast = true
            return
          }
        }
        this.showLoading = true
        let res = await updatePassengerStatus({
          uuid: this.uuid,
          op: this.order.op,
          orderId: this.order.orderId,
          changedEmail: email ? email : null
        })
        this.showLoading = false
        if (res.data.code == 0) {
          this.showDialog = false
          this.toastMsg = res.data.message
            ? res.data.message
            : this.order.op == 'changeEmail'
              ? '修改成功'
              : '重发成功'
          this.showToast = true
          location.reload()
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      }
    },
    repay(order) {
      if (order.stName == '流量包' || order.stName == '下载流量') {
        this.$router.push(`/accelerator/order?orderid=${order.orderId}`)
      } else if (order.stName == '芝麻车' || order.stName == '高级芝麻车') {
        this.$router.push(`/carpool/shangche?orderid=${order.orderId}`)
      } else if (order.stName == '快捷车' || order.stName == '高级快捷车') {
        this.$router.push(`/carpool/shangche?orderid=${order.orderId}`)
      } else if (order.stName == '包车' || order.stName == '高级包车') {
        this.$router.push(`/carpool/shangche?orderid=${order.orderId}`)
      } else if (order.type == 5) {
        this.$router.push(`/eshop/order?orderid=${order.orderId}`)
      } else if (order.type == 7) {
        this.$router.push(`/carpool/upgrade?orderid=${order.orderId}`)
      }
    },
    viewCodes(order) {
      this.order = order
      this.dialogType = 3
      this.showDialog = true
    },
    reSendEmail(order) {
      if (order.status == 8) {
        // 订单到期，有可能需要换邮箱
        this.dialogType = 6
      } else {
        this.dialogType = 1
      }
      this.order.orderId = order.orderId
      this.order.email = order.email
      this.order.op = 'reSendEmail'
      this.order.status = order.status
      this.showDialog = true
    },
    changeEmail(order) {
      this.order.status = order.status
      this.order.orderId = order.orderId
      this.order.op = 'changeEmail'
      this.order.email = order.email
      this.dialogType = 2
      this.showDialog = true
    },
    async searchOrder() {
      if (this.uuid || this.contact) {
        this.showLoading = true
        let res = await getOrders({
          contact: this.contact,
          uuid: this.uuid,
          orderId: this.order.orderId
        })
        this.showLoading = false
        if (res.data.code == 0) {
          res.data.orders = res.data.orders.map(order => {
            order.status == 2
              ? (order.color = '#FCB924')
              : order.status === 3
                ? (order.color = '#FF4242')
                : order.status == 4
                  ? (order.color = '#54CEA8')
                  : order.status == 6
                    ? (order.color = '#FF4242')
                    : order.status == 7
                      ? (order.color = '#A8A8A8')
                      : (order.color = '#35A9FF')
            return order
          })
          this.orders = res.data.orders.map(order => {
            if (order.type == 5) {
              order.codes = order.meta.codes || []
            }
            return order
          })
          if (!this.orders.length && this.contact) {
            this.orderFlag = true
          }
        }
      }
    },
    async updateUser(requestBody) {
      try {
        let res = await updateUser(requestBody)

        if (res.data.code == 0) {
          this.toastMsg = '同步成功'
          this.showToast = true
          this.syncDialogShow = false
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  async created() {
    let ua = navigator.userAgent.toLowerCase()
    this.isWeixin = ua.indexOf('micromessenger') !== -1
    await this.searchOrder()

    if (this.$route.query.phoneNumber) {
      this.phoneNumber = this.$route.query.phoneNumber
    }

    if (this.$route.query.code) {
      let requestParams = {
        code: this.$route.query.code,
        uuid: localStorage.getItem('uuid')
      }

      if (this.phoneNumber) {
        requestParams['phoneNumber'] = this.phoneNumber
      }

      await updateUser(requestParams)

      let newQuery = JSON.parse(JSON.stringify(this.$route.query))
      delete newQuery.code
      this.$router.replace({ query: newQuery })
    }
  }
}
</script>

<style lang="less" scoped>
.order-wrapper {
  width: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  height: 100%;

  input {
    width: 5.32rem;
    border: none;
    height: 0.8rem;
    padding: 0 0.24rem;
    font-size: 0.28rem;
    margin: 0;
    outline: none;
    background: #f8f8f8;
    border-radius: 0.06rem;

    &::placeholder {
      font-size: 0.28rem;
      color: #aeaeae;
    }

    &:focus {
      border: none;
    }

    &:active {
      border: none;
    }
  }

  .prompt-header {
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #272727;
    font-size: 0.36rem;
    font-weight: 500;
  }

  .prompt-body {
    margin: 0.12rem 0;

    .email {
      margin-bottom: 0.12rem;
    }

    padding: 0.24rem;
    font-size: 0.28rem;
    color: #333333;
    line-height: 0.4rem;
  }

  .prompt-footer {
    border-top: 0.02rem solid #eeeeee;
    height: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.36rem;
    color: #69d9b6;
    cursor: pointer;
  }

  .sync-dialog-footer {
    width: 85%;
    display: flex;
    justify-content: space-between;

    .op-btn {
      height: 0.72rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #000;
      font-family: Inter;
      font-size: 0.28rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.cancel {
        border-radius: 1.48rem;
        border: 0.01rem solid #e3e3e3;
        width: 2.52rem;
      }

      &.confirm {
        background: #37cdb4;
        color: #fff;
        width: 2.52rem;
        border-radius: 1.48rem;
      }
    }
  }

  .resend-dialog-header {
    width: 100%;
    display: flex;
    color: #333333;
    font-weight: 500;
    font-size: 0.28rem;
    position: relative;
    height: 1rem;
    align-items: center;

    .resend-dialog-header-title {
      margin-left: 0.24rem;
    }

    .close-icon-wrap {
      position: absolute;
      cursor: pointer;
      top: 0.2rem;
      right: 0.24rem;
      width: 0.8rem;
      height: 0.8rem;

      .close-icon {
        width: 0.5rem;
        height: 0.5rem;

        &::before,
        &::after {
          content: '';
          position: absolute;
          /*方便进行定位*/
          height: 0.3rem;
          width: 0.02rem;
          border-radius: 0.04rem;
          top: 0.1rem;
          right: 0.21rem;
          /*设置top和right使图像在20*20框中居中*/
          background: rgba(0, 0, 0, 0.5);
        }

        &::before {
          transform: rotate(45deg);
          /*进行旋转*/
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .resend-dialog-body {
    font-size: 0.28rem;
    font-weight: 400;
    flex: 1;
    color: #333333;
    line-height: 0.4rem;

    .resend-pic {
      width: 7.02rem;
      margin-top: 0.24rem;

      &:first-child {
        margin-top: 0.36rem;
      }
    }

    padding: 0.24rem;
  }

  .invitation-detail {
    margin-top: 0.3rem;
    background: #f9f9f9;
    padding: 0.24rem;

    border-radius: 0.16rem;

    .invitation-tip {
      font-size: 0.28rem;
      font-weight: 500;
      color: #333333;
    }

    .invitation-situation-box {
      margin-top: 0.4rem;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      .situation-label {
        font-weight: 400;
      }

      .situation-check {
        width: 0.5rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .check {
          width: 0.28rem;
          height: 0.28rem;
          border-radius: 50%;
          border: 0.03rem solid #969799;
        }
      }
    }
  }

  .resend-dialog-footer {
    height: 1.1rem;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;

    .letter-cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.32rem;
      color: #666666;
      cursor: pointer;
      width: 3.32rem;
      height: 0.8rem;
      background: #ebebeb;
      border-radius: 0.4rem;
    }

    .letter-confirm {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.32rem;
      height: 0.8rem;
      background: #4a4a4a;
      border-radius: 0.4rem;
      font-size: 0.32rem;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .coupons-list {
    flex: 1;
    display: flex;
    width: 6.44rem;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
    flex-direction: column;

    .coupon-box {
      margin-top: 0.12rem;

      &:first-child {
        margin-top: 0.24rem;
      }

      cursor: pointer;
      flex-shrink: 0;
      white-space: nowrap;
      width: 6rem;
      height: 1.84rem;
      position: relative;
      display: flex;

      .coupon-head {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 2.02rem;
        height: 1.84rem;
        background: #55cfa5;
        border-radius: 0.04rem 0px 0px 0.04rem;

        &.unavailable {
          background: #cccccc;
        }

        .coupon-amount-info {
          font-weight: 500;
          font-size: 0.72rem;
          color: #ffffff;
          align-items: center;
          display: flex;

          .coupon-currency {
            font-size: 0.24rem;
            margin-top: 0.24rem;
            margin-left: 0.05rem;
          }
        }

        .coupon-condition {
          font-size: 0.24rem;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .coupon-body {
        &.unavailable {
          background: #f2f2f2;
        }

        display: flex;
        height: 1.84rem;
        width: 5rem;
        background: #e0fdf3;
        border-radius: 0rem 0.04rem 0.04rem 0;

        .coupon-info {
          width: 3.92rem;
          height: 100%;
          display: flex;
          flex-direction: column;
          font-weight: 500;
          justify-content: center;
          font-size: 0.28rem;
          color: #333333;
          margin-left: 0.36rem;

          .coupon-expiration {
            color: #666666;
            margin-top: 0.24rem;
          }
        }

        .coupon-check {
          width: 1.08rem;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .check {
            width: 0.28rem;
            height: 0.28rem;
            border-radius: 50%;
            border: 0.03rem solid #969799;
          }
        }
      }
    }
  }

  .coupon-dialog-footer {
    width: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.32rem;
    color: #ffffff;
    font-weight: 500;
    height: 0.92rem;
    border-radius: 0.04rem;
    cursor: pointer;
    background: #55cfa5;
  }

  .search-input {
    margin-top: 0.26rem;
    width: 7.02rem;
    height: 0.76rem;
    display: flex;
    align-items: center;

    .input-suffix {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 0.62rem;
      height: 0.76rem;
      background: #eaeef1;
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;

      svg {
        width: 0.36rem;
        height: 0.36rem;
      }
    }

    input {
      font-size: 0.28rem;
      border: 0;
      width: 6.4rem;
      border-radius: 0 0.4rem 0.4rem 0;
      height: 0.76rem;
      background: #eaeef1;
      outline: none;
      padding: 0;

      &::placeholder {
        font-size: 0.28rem;
        color: #a6aaae;
      }
    }
  }

  .search-button {
    margin-top: 0.26rem;
    width: 7.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      cursor: pointer;
      outline: none;
      border: none;
      text-align: center;
      line-height: 0.8rem;
      width: 2.2rem;
      height: 0.8rem;
      border-radius: 0.4rem;
      font-size: 0.32rem;
      color: #ffffff;
      background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
    }

    .disabled {
      color: #969696;
      background: #f2f2f2;
    }
  }

  .order-list {
    width: 7.02rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0.24rem;

    .order {
      margin-bottom: 0.12rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 0.06rem;
      box-shadow: 0px 0px 0.08rem 0.04rem rgba(0, 0, 0, 0.04);
      font-size: 0.28rem;
      color: #333333;

      .order-first-line {
        margin-top: 0.12rem;
        width: 6.54rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .order-second-line {
        margin-top: 0.12rem;
        width: 6.54rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .car-type {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.24rem;
          color: #44d7b6;
          border: 0.02rem solid #44d7b6;
          border-radius: 0.06rem;
          padding-left: 0.12rem;
          padding-right: 0.12rem;
          height: 0.44rem;
        }
      }

      .order-third-line {
        margin-top: 0.12rem;
        width: 6.54rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .repay-line {
        margin-top: 0.12rem;
        margin-bottom: 0.24rem;
        width: 6.54rem;
        display: flex;

        .repay-btn {
          cursor: pointer;
          height: 0.56rem;
          padding-left: 0.24rem;
          padding-right: 0.24rem;
          background: #ff4865;
          border-radius: 0.06rem;
          margin-right: 0.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 0.24rem;

          &.limited {
            background: #d0d0d0;
          }
        }
      }
    }
  }

  .result-empty {
    margin-top: 1rem;
    font-size: 0.28rem;
    color: #ff3232;
  }

  .wx-result-empy {
    margin-top: 2rem;
    font-weight: blod;
    font-size: 0.32rem;
    color: #c4c4c4;
  }

  .order-desc {
    border-radius: 0.16rem;
    width: 6.54rem;
    // height: 4.78rem;
    background: linear-gradient(180deg,
        #e3fdf5 0%,
        rgba(228, 253, 245, 0.18) 100%);
    padding-left: 0.24rem;
    padding-right: 0.24rem;
    padding-top: 0.36rem;
    font-size: 0.28rem;
    color: #666666;
    line-height: 0.43rem;

    .order-status {
      margin-left: 0.42rem;
      margin-bottom: 0.4rem;
    }
  }

  .confirm-title {
    font-size: 0.36rem;
    font-weight: bold;
    color: #2c2c2c;
    height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .letter-body {
    font-size: 0.28rem;
    flex: 1;
    color: #333333;
    font-weight: 400;
    padding: 0 0.24rem 0rem 0.3rem;
    line-height: 0.44rem;

    .paragraph {
      margin-top: 0.14rem;
    }

    .refund-info {
      background: #e5fdf6;
      border-radius: 6px;
      padding: 0.24rem;
      margin-top: 0.24rem;
    }
  }
}
</style>
