<style lang="less" scoped>
.confirm-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  .confirm-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
    .close-area {
      flex: 1;
    }
    .confirm {
      width: 6.44rem;
      background: white;
      border-radius: 0.16rem;
      display: flex;
      flex-direction: column;
      .header {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.36rem;
        height: 1.1rem;
        font-weight: 500;
        color: #2c2c2c;
      }
      .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .footer {
        height: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .close-area {
      flex: 1;
    }
  }
}
</style>
<template>
  <div class="confirm-mask" v-if="visible">
    <div class="confirm-container">
      <div class="close-area" @click="closeDialog"></div>
      <div class="confirm">
        <div class="header">
          <slot name="header"></slot>
        </div>
        <div class="body">
          <slot name="body"></slot>
        </div>

        <div class="footer">
          <slot name="footer"></slot>
        </div>
      </div>
      <div class="close-area"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    confirmText: {
      type: String,
      default: '已完成支付'
    },
    visible: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: '重新支付'
    }
  },
  methods: {
    closeDialog () {
      this.$emit('cancel')
    },
    cancel () {
      this.$emit('cancel')
    },
    confirm () {
      this.$emit('confirm')
    }
  }
}
</script>
