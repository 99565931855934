<template>
  <div class="dialog-mask" v-if="visible">
    <div class="containner">
      <div class="blank"></div>
      <div class="dialog-wrap">
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    confirmText: {
      type: String,
      default: '退款并领取优惠'
    },
    visible: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: '暂不'
    }
  },
  data () {
    return {}
  },
  methods: {
    close () {
      this.$emit('cancel')
    },
    cancel () {
      this.$emit('cancel')
    },
    confirm () {
      this.$emit('confirm')
    }
  }
}
</script>
<style scoped lang="less">
.dialog-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.65);
  .containner {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .blank {
      flex: 1;
      width: 7.5rem;
    }
    .dialog-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 7.5rem;
      max-height: 10.4rem;
      min-height: 10.4rem;
      background: #ffffff;
      padding-bottom: 0.24rem;
      border-radius: 0.2rem 0.2rem 0px 0px;
    }
  }
}
</style>
