<template>
  <div class="synchronize" :style="styleCustom">
    <div class="synchronize-text">
      在 youthswitch 小程序上查看订单更方便，点击以下按钮，同步订单到小程序！
    </div>
    <div class="synchronize-op">
      <div class="op-wrapper">
        <div class="op-btn" @click="sync1">直接同步</div>
        <div v-if="isWeixin" class="op-desc">
          当前页面有订单记录的用户可直接同步
        </div>
      </div>
      <div class="op-wrapper">
        <div class="op-btn" @click="sync2">手机号同步</div>
        <div v-if="isWeixin" class="op-desc">
          当前页面无订单记录，或之前是用手机号下单的用户可同步
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'synchronize',
  props: {
    styleCustom: {
      type: Object
    },
    isWeixin: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    sync1 () {
      this.$emit('sync1')
    },
    sync2 () {
      this.$emit('sync2')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.synchronize {
  width: 6.82rem;
  display: flex;
  align-items: center;
  border-radius: 0.12rem;
  background: #fff;
  margin-top: 0.24rem;
  box-shadow: 0px 0px 0.05rem 0.04rem rgba(0, 0, 0, 0.03);
  flex-direction: column;
  .synchronize-text {
    display: inline-block;
    font-size: 0.28rem;
    color: #000;
    margin: 0.24rem;
  }
  .synchronize-op {
    width: 85%;
    display: flex;
    margin-top: 0.08rem;
    margin-bottom: 0.25rem;
    justify-content: space-between;
    .op-wrapper {
      display: flex;
      flex-direction: column;
      width: 2.68rem;
      .op-btn {
        color: #fff;
        text-align: center;
        align-items: center;
        justify-content: center;
        background: #ee9b4f;
        display: flex;
        border-radius: 0.08rem;
        cursor: pointer;
        width: 2.68rem;
        height: 0.84rem;
        flex-shrink: 0;
        font-family: PingFang SC;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.36rem; /* 128.571% */
      }
      .op-desc {
        margin-top: 0.12rem;
        color: #999;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.4rem; /* 166.667% */
      }
    }
  }
}
</style>
